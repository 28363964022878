<template>
  <div class="w-full">
    <portal to="navbar-title">Centre d’aide</portal>
    <RevelationMobileButton />
    <div class="w-full px-16 md:px-10 sm:px-5 pb-72 md:pb-16 bg-promy-gray-50">
      <BackToProfileButton classes="mt-14" />
      <div>
        <h3
          class="
            mt-16
            text-2xl
            md:text-xl
            sm:text-base
            font-bold
            text-promy-primary
            leading-9
          "
          id="faq"
        >
          FAQ
        </h3>
        <accordion class="w-full !mt-5">
          <accordion-item
            class="shadow-box mb-4"
            v-for="(question, index) in questions"
            :key="index"
          >
            <template slot="accordion-trigger">
              <accordion-head
                class="md:text-sm py-5 pl-7"
                :indice="index"
                :item_state="active_accordion"
                :title="question.title"
                :focus_color="{
                  title: 'text-promy-green-350',
                  action_icon: '#81C2DE',
                }"
              />
            </template>
            <template slot="accordion-content">
              <div
                class="
                  bg-white
                  pt-7
                  pb-4
                  p-9
                  border-solid border-t-2
                  rounded-b-xl
                "
              >
                <p
                  class="
                    text-justify
                    w-full
                    2xl:w-4/6
                    xl:w-5/6
                    md:w-4/5
                    pb-8
                    text-promy-gray-200
                    font-arial
                    text-sm
                  "
                  v-html="question.body"
                ></p>
                <a
                  v-if="question.text_url"
                  class="
                    flex
                    justify-end
                    items-center
                    text-promy-green-300
                    font-main
                    text-base
                    font-extrabold
                  "
                  @click="action(question.url_value)"
                  :class="{
                    hidden: question.url_value == 'candidater',
                  }"
                >
                  <div
                    class="
                      border-transparent border-b-2
                      hover:border-promy-green-200
                      duration-200
                    "
                  >
                    <span>{{ question.text_url }}</span>
                    <i class="fa fa-arrow-right pl-2 text-sm"></i>
                  </div>
                </a>
                <img
                  v-if="question.illustration"
                  :src="`images/FAQ/${question.illustration}`"
                  :alt="`illustration-${question.illustration}`"
                />
              </div>
            </template>
          </accordion-item>
        </accordion>
      </div>
      <div>
        <h3
          class="
            mt-16
            text-2xl
            md:text-xl
            sm:text-base
            font-bold
            text-promy-primary
            leading-9
          "
          id="contact_us"
        >
          Contactez-nous
        </h3>
        <div class="mt-9 py-14 sm:py-8 px-16 md:px-7 bg-white rounded-lg">
          <div>
            <h4
              class="
                text-2xl
                md:text-xl
                sm:text-sm
                font-bold
                text-promy-primary
                leading-9
              "
            >
              Votre question n’est pas dans cette F.A.Q ?
            </h4>
            <p class="pt-3 text-promy-gray-200 md:text-sm">
              Il vous suffit de nous contacter à cette adresse :
              <a href="mailto:contact@promy.fr" style="color: dodgerblue"
                >contact@promy.fr</a
              >.
              <br />
              Nos conseillers répondront à vos questions dans les plus brefs
              délais.
            </p>
          </div>
          <validation-observer v-slot="{ invalid }">
            <form class="mt-9 grid grid-cols-2 gap-x-8 gap-y-6">
              <pro-input
                class="md:col-span-2 sm:text-sm"
                classes="py-4"
                label="Prénom"
                placeholder="Prénom"
                :rules="'required'"
                v-model="contact.prenom"
                type="text"
              />
              <pro-input
                class="md:col-span-2 sm:text-sm"
                classes="py-4"
                label="Nom"
                placeholder="Nom"
                :rules="'required'"
                v-model="contact.nom"
                type="text"
              />
              <pro-input
                class="col-span-2 sm:text-sm"
                classes="py-4"
                label="Email"
                :placeholder="'Email'"
                :rules="'required|email'"
                v-model="contact.email"
                type="email"
              />
              <pro-textarea
                classTextArea="pt-4"
                class="col-span-2 sm:text-sm"
                label="Message"
                placeholder="Message"
                :rules="'required'"
                v-model="contact.message"
                type="text"
              />
              <pro-button
                class="
                  w-max
                  mt-9
                  !py-6
                  !px-14
                  sm:!px-[90px]
                  leading-6
                  !rounded-lg
                "
                btn="primary"
                icon
                @click.prevent="sendSupport(invalid)"
              >
                <span>Envoyez</span>
              </pro-button>
            </form>
          </validation-observer>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      isLoggedIn: 'auth/isLoggedIn',
    }),
  },
  data() {
    return {
      questions: {
        q1: {
          title: 'Quelle utilisation faîtes-vous de mes données ?',
          body: 'Attentifs au Règlement Général sur la Protection des Données (RGPD), vos données ne feront pas l’objet, de la part de notre société, d’aucune cession à des tiers.',
        },
        q2: {
          title: 'Je souhaite télécharger le dossier relatif à mon adresse',
          body: 'Rien de plus simple, il vous suffit de cliquer sur "Téléchargez PDF" présent à chaque onglet.',
          illustration: 'telecharger.png',
        },
        q3: {
          title:
            'J’ai envoyé une demande de révélation – quand serais-je recontacté.e ?',
          body: 'Nos équipes d’experts doivent s’assurer personnellement que le potentiel fourni par notre Intelligence Artificielle est correct avant de vous faire un retour. Vous serez recontacté.e 48h après l’envoi de la demande.',
        },
        q4: {
          title: 'Combien de demandes de révélation puis-je faire ?',
          body: "Puisque nous intervenons sur chaque demande de révélation, nous avons pris la décision de limiter le nombre de demandes de révélation à 3/mois par compte. Si vous avez d’autres propriétés à vendre sur lesquelles vous souhaiteriez connaître le potentiel, soumettez votre demande à <a href='mailto:contact@promy.fr' style='color:dodgerblue'>contact@promy.fr</a> afin qu’un de nos conseillers prenne directement contact avec vous.",
        },
        q5: {
          title:
            'Les informations d’urbanisme concernant mon adresse ne sont pas disponibles',
          body: 'Nos équipes s’affairent au quotidien à alimenter l’application avec les données d’urbanisme de chaque commune en France. Si la vôtre n’est pas encore disponible, alertez-nous et nous intégrerons au plus vite ces informations sur votre application.',
        },
        q5: {
          title:
            'Les informations d’urbanisme concernant mon adresse ne sont pas disponibles',
          body: 'Nos équipes s’affairent au quotidien à alimenter l’application avec les données d’urbanisme de chaque commune en France. Si la vôtre n’est pas encore disponible, alertez-nous et nous intégrerons au plus vite ces informations sur votre application.',
        },
        q6: {
          title: 'J’ai trouvé une information erronée',
          body: 'Notre Intelligence Artificielle centralise les données de sites internet officiels régissant le secteur de l’immobilier. Il arrive parfois cependant que certaines informations soient inexactes ou désuètes. N’hésitez pas à nous en faire part afin que nous puissions effectuer lesdites modifications.',
        },
      },
      active_accordion: {
        _value: -1,
        is_active: (idx) => this.active_accordion._value === idx,
        set_active_accordion: (idx) => {
          this.active_accordion._value = idx
        },
        get_active_accordion: () => this.active_accordion._value,
      },
      contact: {
        prenom: this.$store.getters['auth/user'].prenom,
        nom: this.$store.getters['auth/user'].nom,
        email: this.$store.getters['auth/user'].email,
        message: '',
      },
    }
  },
  methods: {
    sendSupport(invalid) {
      invalid
        ? this.toast('Support', 'Tous les champs sont obligatoires.', 'warning')
        : this.$http
            .post(this.isLoggedIn ? 'grand-publics/support' : 'contactez-gugp', this.contact)
            .then(
              () => {
                this.toast(
                  'Support',
                  'Votre message a bien été envoyé.',
                  'success',
                )
                this.isLoggedIn && this.$router.push({ name: 'profile' })
              },
              () => {
                this.toast(
                  'Support',
                  "Votre message n'est pas envoyé.",
                  'warning',
                )
              },
            )
    },
  },
}
</script>
